<template>
    <div>
        <div class="price">
            <span>￥{{arrs.price.toFixed(2)}}</span>
        </div>
        <div class="card">
            <van-cell-group>
                <van-cell title="SIM卡号" :value="arrs.msisdn"/>
                <van-cell title="ICCID" :value="arrs.iccid"/>
                <van-cell title="新套餐" :value="arrs.packName"/>
                <van-cell title="周期数" :value="arrs.period"/>
                <van-cell title="单价" :value="arrs.firstPrice"/>
                <!-- <van-cell title="生效期" :value="arrs.date"/> -->
            </van-cell-group>
        </div>
        <div class="button">
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'chargeUp',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            // this.$api.addToUpPack(this.arrs).then(res => {
            //     if(res.data.code == 0){
            //         localStorage.upOrder = res.data.data
            //         localStorage.removeItem('uppack');
            //     }
            // })
            localStorage.removeItem('uppack');
            this.$router.push({name:'Home'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "支付订单");
        if(!localStorage.uppack){
            this.$toast.fail("未发现订单");
            this.$router.push({name:'upPack'})
        }else{
            this.arrs = JSON.parse(localStorage.uppack)
        }
    }
}
</script>
<style lang="scss" scoped>
.price{
    span{
        color: #EB5E00;font-size: 60px;
    }
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
</style>
    
